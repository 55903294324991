import React from 'react';
import MUITableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import './style.scss';

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function TableBody(props) {
  const { columnData, order, orderBy, selectedOrders, validOrdersResponse, handleClick } = props;

  const isSelected = (propertyId) => selectedOrders.indexOf(propertyId) !== -1;

  const getRowClass = (row) => {
    if (!validOrdersResponse) {
      return 'row';
    }
    if (validOrdersResponse[row.property_id]?.success) {
      return 'row row--success';
    }
    return 'row row--failed';
  };

  const getErrorMessage = (row) => {
    return validOrdersResponse?.[row.property_id]?.message;
  };

  return (
    <MUITableBody>
      {stableSort(columnData, getComparator(order, orderBy)).map((row, index) => {
        const isItemSelected = isSelected(row.property_id);
        const labelId = `table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleClick(event, row.property_id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.property_id}
            selected={isItemSelected}
          >
            {validOrdersResponse?.[row.property_id]?.isDuplicated ? (
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={isItemSelected} />
              </TableCell>
            ) : (
              <TableCell padding="checkbox" />
            )}

            <TableCell align="right" component="th" id={labelId} scope="row" padding="none">
              {row.property_id}
            </TableCell>
            <TableCell align="left" className={getRowClass(row)}>
              {getErrorMessage(row)}
            </TableCell>
            <TableCell align="left">{row.address}</TableCell>
            <TableCell align="left">{row.city}</TableCell>
            <TableCell align="left">{row.state}</TableCell>
            <TableCell align="left">{row.zip_code}</TableCell>
            <TableCell align="left">{row.services}</TableCell>
            <TableCell align="left">{row.unit_status}</TableCell>
            <TableCell align="left">{row.method_of_access}</TableCell>
            <TableCell align="left">{row.lockbox_code}</TableCell>
            <TableCell align="left">{row.lockbox_location}</TableCell>
            <TableCell align="left">{row.gate_code}</TableCell>
            <TableCell align="left">{row.on_site_contact_name}</TableCell>
            <TableCell align="left">{row.on_site_contact_phone}</TableCell>
            <TableCell align="left">{row.order_notes}</TableCell>
          </TableRow>
        );
      })}
    </MUITableBody>
  );
}

export default TableBody;
